import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "./app/store";
import { logout } from "./features/user/Auth";
import { message } from "antd";
//基础URL，axios将会自动拼接在url前
//process.env.NODE_ENV 判断是否为开发环境 根据不同环境使用不同的baseURL 方便调试
let baseURL = "/api/proxy";
let backendURL = "/api";
function createRequest(
  baseURL: string,
  timeout: number = 300000,
  GetHeader: Function | null = null
) {
  //创建axios实例
  const service = axios.create({
    timeout,
    baseURL,
  });

  //统一请求拦截 可配置自定义headers 例如 language、token等
  service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      //配置自定义请求头
      if (GetHeader) {
        config.headers = GetHeader();
      }
      return config;
    },
    (error) => {
      console.log(error);
      Promise.reject(error);
    }
  );

  service.interceptors.response.use((res: AxiosResponse) => {
    if ([40001, 40002, 40003].indexOf(res.data.code) !== -1) {
      store.dispatch(logout);
      window.location.href = `/login`;
    }
    return res;
  });

  //axios返回格式
  interface axiosTypes<T> {
    data: T;
    status: number;
    statusText: string;
  }

  //后台响应数据格式
  //###该接口用于规定后台返回的数据格式，意为必须携带code、msg以及result
  //###而result的数据格式 由外部提供。如此即可根据不同需求，定制不同的数据格式
  interface responseTypes<T> {
    code: number;
    msg: string;
    result: T;
  }

  //核心处理代码 将返回一个promise 调用then将可获取响应的业务数据
  const requestHandler = <T>(
    method: "get" | "post" | "put" | "delete" | "patch",
    url: string,
    params: object = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> => {
    let response: Promise<axiosTypes<responseTypes<T>>>;
    switch (method) {
      case "get":
        response = service.get(url, { params: { ...params }, ...config });
        break;
      case "post":
        response = service.post(url, { ...params }, { ...config });
        break;
      case "put":
        response = service.put(url, { ...params }, { ...config });
        break;
      case "delete":
        response = service.delete(url, { params: { ...params }, ...config });
        break;
      case "patch":
        response = service.patch(url, { ...params }, { ...config });
        break;
    }

    return new Promise<any>((resolve, reject) => {
      response
        .then((res) => {
          //业务代码 可根据需求自行处理
          const data: any = res.data;
          if (res.status === 200 || res.status === 204) {
            //数据请求正确 使用resolve将结果返回
            const r = res?.data?.result
              ? { ...res.data.result, ...res.data }
              : res.data;
            resolve(r);
          } else {
            console.log('then-error: ',res)
            reject(data);
          }
          // if (res.status !== 200 ) {
          //   reject(data);
          // } else {
          //   //数据请求正确 使用resolve将结果返回
          //   const r = res.data.result
          //     ? { ...res.data.result, ...res.data }
          //     : res.data;
          //   resolve(r);
          // }
        })
        .catch((error) => {
          console.log("error---", error);
          let e = JSON.stringify(error);
          message.error(`${error?.response?.data?.message || error?.message}`);
          reject(error);
        });
    });
  };

  // 使用 request 统一调用，包括封装的get、post、put、delete等方法
  const request = {
    get: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
      requestHandler<T>("get", url, params, config),
    post: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
      requestHandler<T>("post", url, params, config),
    put: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
      requestHandler<T>("put", url, params, config),
    delete: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
      requestHandler<T>("delete", url, params, config),
    patch: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
      requestHandler<T>("patch", url, params, config),
  };

  return request;
}

const request = createRequest(baseURL, 300000, () => {
  const userState = store.getState().user;
  return {
    token: userState.token,
  };
});
const backendApi = createRequest(backendURL);
// 导出至外层，方便统一使用
export { request, backendApi };
