import { useEffect, useRef } from "react";
import { GetColor } from './dashboard'
import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/pie"
import "echarts/lib/component/tooltip"
import "./pie.css"
import { t } from "i18next";

export interface PieChartData {
  name: string,
  percentage: number,
  score: number,
}

export interface PieChartProps {
  data: Array<PieChartData>,
  loading: boolean,
  icon: string
}

export default function Pie (props: PieChartProps) {
  const chartRef = useRef<HTMLDivElement>(null); // 访问dom元素
  let myChart: echarts.ECharts | null = null;

  const option = {
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: t("address.riskRank.title"),
        type: 'pie',
        radius: ['40%', '65%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 6,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          }
        },
        labelLine: {
          show: false
        },
        data: props.data.map(i => ({name: i.name, value: i.percentage}))
      }
    ],
    color: props.data.map(i => GetColor(i.score))
  };
  
  useEffect(() => {
    if (!chartRef.current) return
    const chart = echarts.getInstanceByDom(chartRef.current)
    if (chart) {
      // eslint-disable-next-line
        myChart = chart
    } else {
        myChart = echarts.init(chartRef.current)
    }
    myChart.setOption(option as any)
    
    return () => {
      myChart && myChart.dispose()
    }
  })

  return (
    <div className="pie-container">
      {props.data.length > 0 && !props.loading ?
        <div className="chart">
          <div style={{width: "220px", height: "220px", margin: "auto"}} ref={chartRef} />
          <img alt="" src={props.icon} />
        </div>: null}
        
      { props.data.length > 0 && !props.loading ?
        <div className="data-list">
          {props.data.map((i,k) => (
            <div key={"list-"+k} className="data-item">
              <div>
                <div className="data-color" style={{backgroundColor: GetColor(i.score)}}></div>
                <p className="data-name">{i.name}</p>
              </div>
              <p className="data-score">{i.percentage}%</p>
            </div>
          ))}
        </div> : null }
    </div>
  )
}