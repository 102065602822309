import styled from './input.module.css'

export default function Input (prop: { type: string, title: string, defaultValue?: string, onChange?: Function, maxNumber?: number, minNumber?: number, placeholder?: string }) {
  
  return <div className={styled.container}>
    <p className={styled.title}>{prop.title}</p>
    <input type={prop.type} value={prop.defaultValue} onChange={(e) => {
      if (prop.type === "number" && e.target.value !== "") {
        const n = Number(e.target.value)
        if (prop.maxNumber && n > prop.maxNumber) {
          e.target.value = prop.maxNumber.toString()
          return
        }
        if (prop.minNumber && n < prop.minNumber) {
          e.target.value = prop.minNumber.toString()
          return
        }
        if (e.target.value[0] === "0") {
          e.target.value = ""
          return
        }
      }
      prop.onChange && prop.onChange(e.target.value)
    }} placeholder={prop.placeholder && prop.placeholder} onKeyUp={(e) => {
      const target = e.target as HTMLInputElement
      target.value = target.value.replace(/\D/g,'')
    }} />
  </div>
}