import { RootState, store } from "@/app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Navigate } from "react-router-dom";
import localData from '@/localData'
import { useAppSelector } from "@/app/hooks";

export interface UserInfo {
  email: string;
  avatar: string;
  caseId: string;
  token: string;
  logout?: boolean;
}

function loadUserInfo(): UserInfo {
  let data: UserInfo = {
    email: "",
    avatar: "",
    token: "",
    caseId: "",
  }
  if (localData.Exist("userinfo")) {
    let parsed = JSON.parse(localData.GetV("userinfo") || "{}")
    if (parsed.email !== undefined) {
      data = parsed
    }
  }
  return data
}

const initialState: UserInfo = loadUserInfo()

// mobile: localData.GetV(UserInfoKEY.MOBILE) || '',
// XApiKey: localData.GetV(UserInfoKEY.API) || ''

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.email = action.payload.email
      state.avatar = action.payload.avatar
      state.token = action.payload.token
      state.caseId = action.payload.caseId
      localData.SetV("userinfo", JSON.stringify(action.payload))
    },
    logout: (state) => {
      localData.Remove("userinfo")
      state.email = ""
      state.avatar = ""
      state.logout = true
      state.token = ""
      state.caseId = ""
    }
  },
});

export default userSlice.reducer 

export const { setUserInfo, logout } = userSlice.actions

export const selectUserLogout = (state: RootState) => state.user.logout === true
export const selectUserInfo = (state: RootState) => state.user

export function IsLogin(): boolean {
  const userState = store.getState().user
  return userState.email !== ""
}

export function CheckAuth(props: any) {
  const userCaseInfo = useAppSelector(selectUserInfo)
  const ulogout = useAppSelector(selectUserLogout)

  if (ulogout) {
    window.location.href = `${window.location.origin}/login`
    return <></>
  }

  if (userCaseInfo.token === "") {
    window.location.href = `${window.location.origin}/login?redirect=${window.location.href}`
    return <></>
  }

  return IsLogin() ? 
  <props.element />:
  <Navigate to={`/login`} />
}

