import styled from './inputWithSelect.module.css'
import bottomIcon from "@/assets/bottom.png"
import { useState } from 'react'
import { EventCallback, removeClickEvent, setClickEvent } from '@/app/util'

let listenClickEvent: EventCallback

export default function InputWithSelect (prop: { type: string, title: string, defaultValue?: string, onChange?: Function, options: Array<string>, defaultOption?: number, onChangeOptions?: Function, placeholder?: string }) {
  const [selectIndex, changeIndex] = useState(prop.defaultOption || 0)
  const [showSelector, setShowSelector] = useState(false)
  const value = prop.options[selectIndex]

  return <div className={styled.container}>
    <p className={styled.title}>{prop.title}</p>
    <input type={prop.type} value={prop.defaultValue} onChange={(e) => prop.onChange && prop.onChange(e.target.value)} placeholder={prop.placeholder&&prop.placeholder} />

    <div className={styled.select} onClick={()=>{
      setShowSelector(true)

      removeClickEvent(listenClickEvent)
      listenClickEvent = () => {
        removeClickEvent(listenClickEvent)
        setShowSelector(false)
      }
      setClickEvent(listenClickEvent)
    }}>
      <div className={styled.content}>
      <span>{value}</span>
      </div>
      <img className={styled.selectIcon} src={bottomIcon} alt="" />
    </div>

    {showSelector ? (
      <div className={styled.option}>
        {
          prop.options.map((i, k) => (
            <div key={`skey${k}`} className={styled.content} onClick={() => {
              prop.onChangeOptions && prop.onChangeOptions(k)
              setShowSelector(false)
              changeIndex(k)
            }}>
              <span>{i}</span>
            </div>
          ))
        }
      </div>
    ) : null}
  </div>
}