import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import routes from './routes'
import authRoutes from './routes/authorization'
import { CheckAuth } from './features/user/Auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Animation.css"
import ScorllTop from "@/app/scrollTop"

function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <ScorllTop>
            <Routes>
                {routes.map(
                  ({path, component: Component, ...routes}) => 
                    <Route key={path} path={path} element={<Component />} {...routes}/>
                )}
                
                {authRoutes.map(
                  ({path, component: Component, ...routes}) => 
                    <Route key={path} path={path} element={<CheckAuth element={Component} />} {...routes}/>
                )}

                <Route path="*" element={<Navigate to={`/login`} />} {...routes}/>
            </Routes>
          </ScorllTop>
        </BrowserRouter>
      </div>
  );
}

export default App;
