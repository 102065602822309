import styled from "./upload.module.css"
import uploadIcon from "@/assets/upload.png"
import closeIcon from "@/assets/x.png"
import { useRef, useState } from "react";
import { isPicture, pictureToBase64 } from "@/app/util";
import LoadingIcon from "@/features/common/loading"
import { PostFile } from "../user/userAPI";

function UploadItem(props: {onUpload: Function, onDelete?: Function, accept: string, id?: number, name?: string, base64?: string, loading?: boolean, multiple?: boolean, errorText?: string}) {
  const inputRef = useRef<HTMLInputElement>(null);

  return <div className={styled["picture-wrap"]}>
  { props.errorText ? <span className={styled["picture-error"]}>{props.errorText}</span>
    : props.name }
  
  { !props.loading && (props.id || props.errorText) ? 
    <img className={styled["picture-close"]} src={closeIcon} alt="" onClick={() => props.onDelete && props.onDelete()} /> : null
  }
  
  { props.loading ? 
    <div className={styled["picture-loading"]}>
      <LoadingIcon size={40} color={"#ffffff"} />
    </div> : <img className={styled["picture-upload"]} src={uploadIcon} alt="" onClick={() => {
      inputRef.current?.click()
    }} />
  }

  {!props.errorText && props.base64 != "" ? <img className={styled["picture-user"]} src={props.base64} alt="" /> : null}
  <input accept={props.accept} multiple={props.multiple} type="file" onChange={(e) => {
    const file = e.target.files
    if (file && file.length > 0) {
      props.onUpload(file)
    }
  }} ref={inputRef} />
</div>
}

export interface ImgItem {
  id: number,
  loading: boolean,
  base64: string,
  error: string,
  name: string,
}
export default function Upload(props: {
  onUpload: (index: number, file: Array<File>) => void,
  onDelete: (index: number) => void,
  title: string,
  accept: string,
  data: Array<ImgItem>,
  alertText?: string,
}) {
  return <div className={styled["multiple-upload-component"]}>
    <p className={styled["upload-title"]}>{props.title}</p>

    <div className={styled["picture-list-wrap"]}>
      {
        props.data.map((i,k) => <UploadItem key={"upload-item-" + k} onUpload={(file: Array<File>) => {
          props.onUpload(k, file)
        }} onDelete={() => props.onDelete(k)} accept={props.accept} loading={i.loading} base64={i.base64} name={i.name} id={i.id}  errorText={i.error} />)
      }
      {
        props.data.length < 5 ? <UploadItem onUpload={(file: Array<File>) => {
          props.onUpload(-1, file)
        }} accept={props.accept} multiple={true} /> : null
      }
    </div>

    <p className={styled["upload-alert"]}>{props.alertText}</p>

  </div>
}