import { useTranslation } from "react-i18next";
import "./assetsList.css";
import { GetColor } from "../common/dashboard";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  blockchainTranslate,
  CalcAssetsScore,
  reverseCoins,
  selectCoins,
  setCalcScoreStatus,
} from "./addressSlice";
import { useState } from "react";
import { AnalysisType } from "./addressAPI";
import { useParams } from "react-router-dom";

import { Button, Col, Form, Input, Row, Space, Modal, Table, Tag } from "antd";
import dayjs from "dayjs";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
export function ColorElm(props: { score: number }) {
  return (
    <span
      style={{
        width: "45px",
        height: "16px",
        textAlign: "center",
        lineHeight: "16px",
        fontSize: "10px",
        fontWeight: "bold",
        display: "inline-block",
        borderRadius: "24px",
        color: "#ffffff",
        backgroundColor: GetColor(props.score),
      }}
    >
      {props.score}
    </span>
  );
}

export function CoinElm(props: { coinSrc: string; coinName: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        style={{ width: "15px", height: "15px" }}
        src={props.coinSrc}
        alt=""
      />
      <span
        style={{
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "14px",
          color: "#425466",
          paddingLeft: "7px",
        }}
      >
        {props.coinName}
      </span>
    </div>
  );
}

export default function AssetsList() {
  const { t } = useTranslation();

  const { id, blockchain } = useParams();

  const coins = useAppSelector(selectCoins);
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);

  if (!coins) return null;
  const clickButton = (key: number, clickType: number) => {
    if (!blockchain || !id) return;
    dispatch(
      CalcAssetsScore({
        analysisType:
          clickType === 0 ? AnalysisType.Incoming : AnalysisType.Outgoing,
        blockchain,
        objectId: id,
        coin: coins[page * 4 + key].chainId || "MAIN",
        index: page * 4 + key,
      })
    );
    dispatch(
      setCalcScoreStatus({
        index: page * 4 + key,
        type: clickType,
        status: true,
      })
    );
  };

  // (i.calcIncomeScore ? loadingElement
  //   (i.calcSpendingScore ? loadingElement
  const columns: ColumnsType<any> = [
    {
      title: t("address.assetsName"),
      dataIndex: "assetsName",
      key: "assetsName",
      width: 100,
      fixed: true,
      render: (text, i) => {
        return (
          <CoinElm coinSrc={i.icon} coinName={blockchainTranslate(i.name)} />
        );
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: 100,
      fixed: true,
      render: (i, data) => {
        return (
          <>
            {i} {data?.symbol}
          </>
        );
      },
    },
    {
      title: t("amountDollar"),
      dataIndex: "balanceUsd",
      key: "balanceUsd",
      width: 100,
      render: (i, data) => {
        return <>{i} USD</>;
      },
    },
    {
      title: "Transactions",
      dataIndex: "numberOfTransactions",
      key: "numberOfTransactions",
      width: 100,
      render: (i, data) => {
        return <>{i || "N/A"} </>;
      },
    },
    {
      title: "Incoming Risk Score",
      dataIndex: "incomeScore",
      key: "incomeScore",
      width: 100,
      render: (i, data, key) => {
        if (i === 0) {
          return <>N/A</>;
        } else if (i > 0) {
          return <ColorElm score={i} />;
        } else {
          return (
            <div
              onClick={() => clickButton(key, 0)}
              style={{ cursor: "pointer", color: "rgb(0, 128, 255)" }}
            >
              View Score
            </div>
          );
        }
      },
    },
    {
      title: "Outgoing Risk Score",
      dataIndex: "spendingScore",
      key: "spendingScore",
      width: 100,
      render: (i, data, key) => {
        if (i === 0) {
          return <>N/A</>;
        } else if (i > 0) {
          return <ColorElm score={i} />;
        } else {
          return (
            <div
              onClick={() => clickButton(key, 1)}
              style={{ cursor: "pointer", color: "rgb(0, 128, 255)" }}
            >
              View Score
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className="assets-container">
      <div>
        <p className="assets-title">{t("address.assetsList")}</p>
        <Table
          scroll={{ x: "800" }}
          columns={columns}
          dataSource={coins}
          pagination={{
            showSizeChanger: false,
            total: coins.length,
            pageSize: 4,
            onChange: (a, b) => {
              setPage(a - 1);
            },
          }}
        />
      </div>
    </div>
  );
}
