import { useTranslation } from 'react-i18next';
import SubmitSuccessIcon from "@/assets/submitSuccess.png"
import styles from './user.module.css';
import { useNavigate } from 'react-router';

export default function RegisterSuccess() {
  const {t} = useTranslation()
  const navigate = useNavigate()

  return <div className={styles["register-success-win"]}>
    <div className={styles["register-success-body"]}>
      <img src={SubmitSuccessIcon} alt="" />
      <span>{t("register.registerSuccess")}</span>
    </div>
    <div
      className={styles["login-button"]}
      onClick={() => navigate("/login")}
    >
      {t("register.backLogin")}
    </div>

  </div>
}