import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '@/features/user/userSlice';
import userReducer from '@/features/user/Auth';
import searchReducer from '@/features/search/SearchSlice';
import addressReducer from '@/features/address/addressSlice';
import entityReducer from '@/features/entity/entitySlice';
import transactionReducer from '@/features/transaction/transactionSlice';
import alertReducer from '@/features/alert/alertSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    user: userReducer,
    search: searchReducer,
    address: addressReducer,
    entity: entityReducer,
    transaction: transactionReducer,
    alert: alertReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
