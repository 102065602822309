/* eslint-disable import/no-anonymous-default-export */
import {
    Badge,
} from "antd";
export const riskLevelText = [
    {
        key: "CRITICAL_RISK",
        label: <Badge color="#425466" text="Critical" />,
    },
    {
        key: "HIGH_RISK",
        label: <Badge color="#D44333" text="High" />,
    },

    {
        key: "MEDIUM_RISK",
        label: <Badge color="#FEBE2D" text="Medium" />,
    },
    {
        key: "LOW_RISK",
        label: <Badge color="#009A4D" text="Low" />,
    },
];
export const statusText = [
    {
        key: "OPEN",
        label: <Badge color="#425466" text="Open" />,
        text:'Open',
    },
    {
        key: "IN_REVIEW",
        label: <Badge color="#7B1AF8" text="In review" />,
        text:'In review'
    },
    {
        key: "RESOLVED",
        label: <Badge color="#1F8070" text="Resolved" />,
        text:'Resolved'
    },
    {
        key: "CLOSED",
        label: <Badge color="#0777A4" text="Closed" />,
        text:'Closed'
    },
];
export const actionStatusText = [
    {
        key: "REPORTED",
        label: <Badge color="#606E7B" text="Reported" />,
        text:'Reported'
    },
    {
        key: "BLOCKLISTED",
        label: <Badge color="#606E7B" text="Blocklisted" />,
        text:'Blocklisted'
    },
    {
        key: "SCORED",
        label: <Badge color="#606E7B" text="Scored" />,
        text:'Scored'
    },
    {
        key: "OTHER",
        label: <Badge color="#606E7B" text="Other" />,
        text:'Other'
    },
    {
        key: "EMPTY",
        label: <Badge color="#606E7B" text="No action" />,
        text:'No action"'
    },
];
