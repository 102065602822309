/* eslint-disable import/no-anonymous-default-export */
import { useTranslation } from "react-i18next";
import "./assetsList.css";
import { useAppSelector } from "@/app/hooks";
import { reverseRegulation, selectRegulation } from "../entity/entitySlice";
import { Button, Col, Form, Input, Row, Space, Modal, Table, Tag } from "antd";
import dayjs from "dayjs";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
export default () => {
  const { t } = useTranslation();
  const regulation = useAppSelector(selectRegulation);
  if (!regulation) return null;
  const columns: ColumnsType<any> = [
    {
      title: 'Country',
      dataIndex: "country",
      key: "country",
      width: 150,
      fixed: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a.country.localeCompare(b.country);
      },
    },
    {
      title: 'Name',
      dataIndex: "name",
      width: 400,
      fixed: true,
      key: "name",
  
    },
    {
      title: 'Creation Date',
      dataIndex: "statusStartDate",
      key: "statusStartDate",
      width: 150,

    },
    {
      title: 'Status',
      dataIndex: "regulatoryStatus",
      key: "regulatoryStatus",
      width: 150,
  
    },
    {
      title:'Supervisory Authority',
      dataIndex: "supervisoryAuthority",
      key: "supervisoryAuthority",
      width: 200,
  
    },
    {
      title:  'Status Expiry Date',
      dataIndex: "statusExpiryDate",
      key: "statusExpiryDate",
      width: 2000,
    },


  ];
  return (
    <div className="assets-container">
      <div>
        <p className="assets-title">{t("entities.regulatory")}</p>
        <Table
            scroll={{ x: "1000" }}
            columns={columns}
            dataSource={regulation}
            pagination={{
              showSizeChanger: false,
              pageSize:4
            }}
          />
      </div>
    </div>
  );
};
