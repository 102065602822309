import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const AutoScorllTop = (props: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return props.children;
};

export default AutoScorllTop;