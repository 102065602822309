import { formatData } from "@/app/util"
import { request } from "@/request"

export interface AlertCoins {
  name: string,
  symbol: string,
  blockchain: string,
  icon: string,
  id: number
}
// 弹窗获取资产列表
export function GetAlertCoins(blockchain: string) {
  return new Promise<Array<AlertCoins>>(async (resolve, reject) => {
    const r = await request.get<any>(`/v1/coins?blockchain=${blockchain}`)
    resolve(r.items.map((i: any) => ({
      name: i.name,
      symbol: i.symbol,
      blockchain: i.blockchain,
      icon: i.icon,
      id: i.id
    })))
  })
}


export interface AlertItem {
  "id": number,
  "address": string,
  "isEntity": boolean,
  "entityName": string,
  "type": string,
  "blockchain": string,
  "isEnabled": boolean,
  "isMailEnabled": boolean,
  "creationDate": string,
  "lastTriggerDate": string,
  "conditions": {
    "coinId": number,
    "currency": string | null,
    "amount": number | null,
    "activityType": ActiveType | null,
    "scoringType": ScoringType | null,
    "thresholdScore": number | null
  },
  "triggerCount": number,
  "changingEnable": boolean,
  "changingEmail": boolean,
  "deleting": boolean,
}
export interface AlertRes {
  data: Array<AlertItem>,
  total: number
}
// 获取通知设置列表
export function GetAlerts(field: {offset: number, limit: number, address?: string }) {
  return new Promise<AlertRes>(async (resolve, reject) => {
    const r = await request.get<any>(`/v1/alerts?offset=${field.offset}&limit=${field.limit}${field.address ? "&address=" + field.address : ""}`)
    resolve({
      data: r.items.map((i: any) => ({
        "id": i.id,
        "address": i.address,
        "isEntity": i.isEntity,
        "entityName": i.isEntity ? i.entityName : "N/A",
        "type": i.type,
        "blockchain": i.blockchain,
        "isEnabled": i.isEnabled,
        "isMailEnabled": i.isMailEnabled,
        "creationDate": formatData(i.creationDate),
        "lastTriggerDate": i.lastTriggerDate,
        "conditions": {
          "coinId": i.conditions.coinId,
          "activityType": i.conditions.activityType,
          "amount": i.conditions.amount,
          "currency": i.conditions.currency,
          "scoringType": i.conditions.scoringType,
          "thresholdScore": i.conditions.thresholdScore,
        },
        "triggerCount": i.triggerCount,
        "changingEnable": false,
        "changingEmail": false,
        "deleting": false
      })),
      total: r._metadata.total
    })
  })
}


export interface Notification {
  "id": number,
  "isRead": boolean,
  "date": string,
  "details": {
    "type": string,
    "amountTransferred": number,
    "localAmount": number,
    "localCurrency": string,
  },
  "alert": {
    "id": number,
    "address": string,
    "isEntity": boolean,
    "type": string,
    "entityName": string,
    "blockchain": string,
    "isEnabled": boolean,
    "isMailEnabled": boolean,
    "creationDate": string,
    "lastTriggerDate": string,
    "conditions": {
      "coinId": number,
      "currency"?: string,
      "amount"?: number,
      "activityType" ?: ActiveType,
      "scoringType"?: ScoringType,
      "thresholdScore"?: number
    },
    "triggerCount": number
  }
}
export interface NotificationRes {
  data: Array<Notification>,
  total: number
}
// 获取通知列表
export function GetNotifications(field: {offset: number, limit: number, address?: string }) {
  return new Promise<NotificationRes>(async (resolve, reject) => {
    const r = await request.get<any>(`/v1/alerts/notifications?offset=${field.offset}&limit=${field.limit}${field.address ? "&address=" + field.address : ""}`)
    resolve({
      data: r.items.map((i: any) => ({
        "id": i.id,
        "isRead": i.isRead,
        "date": formatData(i.date),
        "alert": {
          "id": i.alert.id,
          "address": i.alert.address,
          "isEntity": i.alert.isEntity,
          "type": i.alert.type,
          "entityName": i.alert.entityName || "N/A",
          "blockchain": i.alert.blockchain,
          "isEnabled": i.alert.isEnabled,
          "isMailEnabled": i.alert.isMailEnabled,
          "creationDate": i.alert.creationDate,
          "lastTriggerDate": i.alert.lastTriggerDate,
          "details": {
            "type": i.details.type,
            "amountTransferred": i.details.amountTransferred,
            "localAmount": i.details.localAmount,
            "localCurrency": i.details.localCurrency,
          },
          "conditions": {
            "coinId": i.alert.conditions.coinId,
            "activityType": i.alert.conditions.activityType,
            "amount": i.alert.conditions.amount,
            "currency": i.alert.conditions.currency,
            "scoringType": i.alert.conditions.scoringType,
            "thresholdScore": i.alert.conditions.thresholdScore,
          },
          "triggerCount": i.alert.triggerCount
        }
      })),
      total: r._metadata.total
    })
  })
}


// 通知开关
export interface PatchParams {isEnabled?: boolean, isMailEnabled?: boolean}
export function PatchAlert(id: number, params: PatchParams) {
  return request.patch<any>(`/v1/alerts/${id}`, params)
}

// 删除
export function DeleteAlert(id: number) {
  return request.delete<any>(`/v1/alerts/${id}`)
}

export enum AlertType {
  activity = "ACTIVITY",
  scoreThreshold = "SCORE_THRESHOLD",
  lowscoreCoinsReceived = "LOWSCORE_COINS_RECEIVED",
  sendToLowscore = "SEND_TO_LOWSCORE",
}

export enum ActiveType {
  all = "ALL",
  credit = "CREDIT",
  debit = "DEBIT",
}
export enum ScoringType {
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
}

export interface SetAlertParams {
  address: string,
  blockchain: string,
  conditions: {
    coinId: number,
    currency?: string,
    amount?: number,
    activityType ?: ActiveType,
    scoringType?: ScoringType,
    thresholdScore?: number
  },
  isEnabled: boolean,
  isEntity: boolean,
  isMailEnabled: boolean,
  type: AlertType
}
// 设置通知
export function SetAlerts(params: SetAlertParams) {
  return request.post<any>(`/v1/alerts`, params)
}