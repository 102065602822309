import { useTranslation } from "react-i18next"
import "./alert.css"
import closeImg from "@/assets/x.png"
import SelectCpt from "@/features/common/select"
import RadioCpt from "@/features/common/radio"
import InputCpt from "@/features/common/input"
import InputWithSelectCpt from "@/features/common/inputWithSelect"
import { useState } from "react"
import Checkboxinfo from "../common/checkboxinfo"
import { toogleAlert } from "../address/addressSlice"
import { ActivityList, AlertTypeList, ScoringList, selectCoins, selectSettingAlert, SetAlert } from "./alertSlice"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { AlertType, SetAlertParams } from "./alertApi"
import { useParams } from "react-router-dom"
import AlertWin from "@/features/common/alert"
import LoadingIcon from "@/features/common/loading"

export default function SetAlertWin () {
  const { t } = useTranslation()

  const { id, blockchain } = useParams();

  const [alertType, setAlertType] = useState(0)
  const [coinIndex, setCoinIndex] = useState(0)
  const [scoringType, setScoringType] = useState(0)
  const [activityType, setActivityType] = useState(0)
  const [amountHighThan, setAmountHighThan] = useState("")
  const [thresholdScore, setThresholdScore] = useState(0)
  const [checkedinfo, setchecked] = useState(false)

  // status : 0 关闭 1 打开 2 关闭后销毁
  const [info, setInfo] = useState({ status: 0, text: "" })

  const dispatch = useAppDispatch()

  const coins = useAppSelector(selectCoins)

  const settingAlert = useAppSelector(selectSettingAlert)

  if (!id || ! blockchain) return null

  const createAlert = async () => {
    if (!coins || settingAlert) return null

    const postParams: SetAlertParams = {
      address: id,
      blockchain,
      conditions: {
        coinId: coins[coinIndex].id,
      },
      isEnabled: true,
      isEntity: false,
      isMailEnabled: checkedinfo,
      type: AlertTypeList[alertType]
    }

    switch(postParams.type) {
      // 检测到活动
      case AlertType.activity:
        if (!amountHighThan) return setInfo({
          status: 1,
          text: t("alert.empty.amount"),
        })
        postParams.conditions.currency = "USD"
        postParams.conditions.activityType = ActivityList[activityType]
        postParams.conditions.amount = parseInt(amountHighThan)
        break

      // 计算风险小于定义
      case AlertType.scoreThreshold:
        if (!thresholdScore) return setInfo({
          status: 1,
          text: t("alert.empty.score"),
        })
        postParams.conditions.thresholdScore = thresholdScore
        postParams.conditions.scoringType = ScoringList[scoringType]
        break

      // 钱包流入流出
      case AlertType.sendToLowscore:
      case AlertType.lowscoreCoinsReceived:
        if (!thresholdScore) return setInfo({
          status: 1,
          text: t("alert.empty.score"),
        })
        if (!amountHighThan) return setInfo({
          status: 1,
          text: t("alert.empty.amount"),
        })

        postParams.conditions.thresholdScore = thresholdScore
        postParams.conditions.currency = "USD"
        postParams.conditions.amount = parseInt(amountHighThan)
        break
    }

    const s = await dispatch(SetAlert(postParams))
    
    const result = s.payload as Array<any>

    setInfo({
      status: result[0],
      text: result[1]
    })
  }

  return (
    <>
      { info.status !== 0 ?
        <AlertWin title={t("handleText")} content={info.text} cancelText={t("alertPage.close")} cancelCallback={() => {
          if (info.status === 2) {
            dispatch(toogleAlert(false))
          } else {
            setInfo({ status: 0, text: "" })
          }
        }} />
        : null }
      <div className="alert-container">
        <div className="alert-win">
          <div className="header">
            <p className="title">{t("alert.title")}</p>
            <img src={closeImg} alt="" onClick={() => dispatch(toogleAlert(false))} />
          </div>
          <div className="alert-body">
  
            <p className="subtitle">{t("alert.subtitle")}</p>

            <div className="alert-forms">

              {/* 警报类型 */}
              <SelectCpt title={t("alert.type")} options={[
                { content: t("alert.alertTypeOption.activityDetected") },
                { content: t("alert.alertTypeOption.crsfbv") },
                { content: t("alert.alertTypeOption.iffwrs") },
                { content: t("alert.alertTypeOption.oftwrs") },
              ]} defaultValue={alertType} onSelect={(index: number) => setAlertType(index)} />
    
              {/* 资产列表 */}
              <SelectCpt title={t("alert.assetName")} options={
                coins ? coins.map((i, k) => (
                  { content: i.name, image: i.icon }
                )) : []
              } defaultValue={coinIndex} onSelect={(index: number) => setCoinIndex(index)} />
    
              {/* 活动类型 */}
              {
                alertType === 0 ? <RadioCpt title={t("alert.activity")} options={[
                  t("alert.activityTypeOption.anybody"),
                  t("alert.activityTypeOption.raise"),
                  t("alert.activityTypeOption.deposit"),
                ]} defaultValue={activityType} onChange={(index: number) => setActivityType(index)} /> : null
              }
    
              {/* 方向 */}
              {
                alertType === 1 ? <RadioCpt title={t("alert.direction")} options={[
                  t("alert.inflows"),
                  t("alert.outflows"),
                ]} defaultValue={scoringType} onChange={(index: number) => setScoringType(index)} /> : null
              }
    
    
              {/* 风险得分小于 */}
              {
                alertType !== 0 ? <InputCpt type="number" title={t("alert.scorelessThan")} onChange={(v: number) => setThresholdScore(v)} maxNumber={100} minNumber={1} placeholder={t("alert.empty.riskScore")} /> : null
              }

              {/* 金额大于 */}
              {
                alertType !== 1 ? <InputWithSelectCpt
                  type="number"
                  title={t("alert.amountHighThan")}
                  onChange={(v: string) => setAmountHighThan(v)}
                  defaultValue={amountHighThan}
                  placeholder={t("alert.empty.amount")}
                  options={[
                    "USD"
                  ]}
                /> : null
              }
            </div>
    
            {/* 邮件通知 */}
            <Checkboxinfo
              content={t("alert.sendEmail")}
              checked={checkedinfo}
              onCheck={() => setchecked(!checkedinfo)}
            />
              
            <div className="button-wrap">
              <button onClick={() => dispatch(toogleAlert(false))} className="button cancel-button" type="button">{t("alert.cancel")}</button>
              
              <div
                className={"button success-button " + (settingAlert ? "loading":"")}
                onClick={() => createAlert() }
              >
                { settingAlert ? <div className="loadicon">
                  <LoadingIcon size={20} color={"#fff"} />
                </div> : null }
                <span>{t("alert.create")}</span> 
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </>

    
  )
}