import { formatData } from "@/app/util";
import { request } from "@/request";
// https://api.scorechain.com/v1/lists?type=CUSTOMER_PORTFOLIO&offset=0&limit=10&severity=UNDEFINED_RISK
// my list id
export function getIdList(params: any) {
  return request.get<any>(`/v1/lists`, params);
}
// 
export function getListsAdress(id: any, params: any) {
  return request.get<any>(`/v1/lists/${id}/addresses`, params);
}

export function deleteItem(id: any, itemId: any) {
  return request.delete<any>(`/v1/lists/${id}/addresses/${itemId}`);
}
