// get local data
function GetV(key: string): string | null {
  return localStorage.getItem(key)
}

// remove local data
function Remove(key: string) {
  localStorage.removeItem(key)
}

// set local data
function SetV(key: string, value: string) {
  localStorage.setItem(key, value)
}

// check local data
function Exist(key: string): boolean {
  return localStorage.getItem(key) !== null
}
// eslint-disable-next-line
export default {
  GetV,
  SetV,
  Remove,
  Exist
}