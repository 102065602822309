import { useTranslation } from "react-i18next"
import "./dashboard.css"

export interface DashboardProps {
  score: number,
  loading: boolean
}

// Critical risk; rgb(233, 1, 1)
// High risk; rgb(226, 83, 3)
// Medium risk; rgb(226, 136, 0)
// Low risk；rgb(61, 187, 2)
// No risk；rgb(63, 153, 0)
export function GetColor(score: number) {
  if (score < 2) {
    return "rgb(233, 1, 1)"
  } else if (score < 30) {
    return "rgb(226, 83, 3)"
  } else if (score < 70) {
    return "rgb(226, 136, 0)"
  } else if (score < 100) {
    return "rgb(61, 187, 2)"
  } else {
    return "rgb(63, 153, 0)"
  }
}

export function GetTextColor(score: number) {
  return "#ffffff";
}

function getLevelKey(score: number) {
  if (score < 2) {
    return "Critical"
  } else if (score < 30) {
    return "High"
  } else if (score < 70) {
    return "Medium"
  } else if (score < 100) {
    return "Low"
  } else {
    return "No"
  }
}

export default function Dashboard (props: DashboardProps) {
  const { t } = useTranslation()
  
  return (
    <div className="dashboard-container">
      {
        props.score !== 0 && !props.loading ? 
          <>
            <div className="arc-wrapper">
              <p className="track-arc"></p>
              <div className="round-box" style={{ transform: `rotate(-${180 * ((100 - props.score) / 100)}deg)` }}>
                <p className="round" style={{ borderColor: GetColor(props.score) }}></p>
              </div>
            </div>
            <p className="score" style={{ color: GetColor(props.score) }}>{props.score}</p><div className="detail-text">
            <div style={{ background: GetColor(props.score) }}></div>
              <p className="level">{t("level." + getLevelKey(props.score))}</p>
            </div>
          </> : null
      }
    </div>
  )
}