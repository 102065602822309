import { useTranslation } from "react-i18next";
import "./assetsList.css";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  blockchainTranslate,
  GetTransaction,
  selectLoadingTransaction,
  selectTransaction,
} from "./addressSlice";
import { useState } from "react";
import LoadingIcon from "@/features/common/loading";
import { useNavigate, useParams } from "react-router-dom";
import { CoinElm } from "./assetsList";
import { Button, Col, Form, Input, Row, Space, Modal, Table, Tag } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
export default function NumberDeals() {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const { type } = useParams();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { blockchain, id } = useParams();
  const transactions = useAppSelector(selectTransaction);
  const loading = useAppSelector(selectLoadingTransaction);
  if (!blockchain || !id || !transactions) return null;
  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 160,
      fixed: true,
    },
    {
      title: "Transaction",
      dataIndex: "hash",
      key: "hash",
      width: 300,
      fixed: true,
      render: (text, i) => {
        return (
          <Link
            style={{ color: "#0080ff" }}
            to={`/trading/${blockchain}/${text}`}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "Asset",
      dataIndex: "Asset",
      key: "Asset",
      width: 300,
      render: (text, i) => {
        return (
          <>
            {i.assetName.map((item: string, index: string | number) => {
              return (
                <CoinElm
                  coinSrc={`${i.assetIcon[index]}`}
                  coinName={blockchainTranslate(item)}
                />
              );
            })}
          </>
        );
      },
    },
    {
      title: "Countervalue(USD)",
      dataIndex: "amountUsd",
      key: "amountUsd",
      width: 200,
      render: (text, i) => {
        return (
          <>
            {text.map((item: string, index: any) => {
              return <div>{item}</div>;
            })}
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (text, i) => {
        return (
          <>
            {text.map((item: string, index: any) => {
              return <div>{item}</div>;
            })}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
      render: (text, i) => {
        return (
          <>
            {text.map((item: string, index: any) => {
              let newStr = item.replace("VALUE_TRANSFER", "Payment");
              newStr = newStr.replace("BLOCK_REWARD", "Block Reward");
              return <div>{newStr}</div>;
            })}
          </>
        );
      },
    },
  ];
  const changePage = (page: number) => {
    dispatch(
      GetTransaction({
        blockchain: blockchain,
        address: id,
        offset: (page - 1) * 4,
        limit: 4,
        type: type === "1" ? "addresses" : "wallets",
      })
    );
    setPage(page - 1);
  };
  return (
    <div className="container-item assets-container numberdeals-container">
      {loading ? (
        <div className="container-loadicon">
          <LoadingIcon size={20} color={"#4C6FFF"} />
        </div>
      ) : null}
      <div className="numberdeals-table">
        <p className="assets-title">Transactions</p>
        <Table
          scroll={{ x: "800px" }}
          columns={columns}
          dataSource={transactions.data}
          pagination={{
            showSizeChanger: false,
            pageSize: 4,
            total: transactions.count,
            onChange: (page: number) => changePage(page),
          }}
        />
      </div>
    </div>
  );
}
