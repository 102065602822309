/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Search } from "@/features/search/Search";
import { useTranslation } from "react-i18next";
import "./notificationsList.css";
import Header from "@/features/user/Header";
import Navigation from "@/features/common/navigation";
import type { TabsProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Modal,
  Dropdown,
  Tabs,
  Table,
  DatePicker,
  Badge,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  ScenariosAlerts,
  PatchScenariosAlerts,
} from "@/features/NotificationsList/NotificationsListApi";
import { riskLevelText, statusText, actionStatusText } from "./config";
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
interface DataType {
  [x: string]: any;
  item: any;
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

function NotificationsList() {
  document.title = "Alert Notifications";
  const { t } = useTranslation();

  const [data1, setData1] = useState([]);
  const [paginationData1, setPaginationData1] = useState({
    count: 10,
    total: 0,
    offset: 10,
    limit: 10,
  });
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });
  const [form] = Form.useForm();
  const [searchForm, setSearchForm] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(1);
  const [modalData, setModalData] = useState<any>({});
  const [modalForm] = Form.useForm();
  const columns1: ColumnsType<DataType> = [
    {
      title: "Date Triggered",
      dataIndex: "date",
      key: "date",
      width: 200,
      fixed: true,
      render: (cell) => {
        return <>{dayjs(cell * 1000).format("YYYY-MM-DD HH:mm")}</>;
      },
    },
    {
      title: "Item",
      dataIndex: "addressHash",
      key: "addressHash",
      width: 330,
      fixed: true,
      render: (text, record) => {
        return (
          <Link
            to={`/notifications/${record?.scenario?.id}/details/${record?.id}`}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      key: "riskLevel",
      width: 150,
      render: (cell) => {
        let label = null;
        riskLevelText.map((status) => {
          if (status.key === cell) {
            label = status.label;
          }
        });
        return <>{label}</>;
      },
    },
    {
      title: "Scenario Name",
      dataIndex: "scenario",
      key: "scenario",
      width: 470,
      render: (text, record) => {
        return (
          <Link
            to={`/notifications/${record?.scenario?.id}/details/${record?.id}`}
          >
            {text.name}
          </Link>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 150,
      render: (cell, record) => {
        let label = null;
        statusText.map((status) => {
          if (status.key === cell) {
            label = status.label;
          }
        });
        return (
          <Dropdown
            menu={{
              items: statusText,
              onClick: (e) => {
                if (e.key === cell) {
                  return;
                }
                let data = {
                  ...record,
                  status: e.key,
                };
                setModalType(1);
                setModalData(data);
                setIsModalOpen(true);
                modalForm.setFieldsValue(data);
              },
            }}
          >
            <a onClick={(e) => e.preventDefault()}>{label}</a>
          </Dropdown>
        );
      },
    },
    {
      title: "Action Status",
      key: "actionStatus",
      dataIndex: "actionStatus",
      width: 150,
      render: (cell, record) => {
        let label = null;
        actionStatusText.map((status) => {
          if (status.key === cell) {
            label = status.label;
          }
          if (cell === null && status.key === "EMPTY") {
            label = status.label;
          }
        });
        return (
          <Dropdown
            menu={{
              items: actionStatusText,
              onClick: (e) => {
                if (e.key === cell || (cell === null && e.key === "EMPTY")) {
                  return;
                }
                let data = {
                  ...record,
                  actionStatus: e.key,
                };
                setModalType(2);
                setModalData(data);
                setIsModalOpen(true);
                modalForm.setFieldsValue(data);
              },
            }}
          >
            <a onClick={(e) => e.preventDefault()}>{label}</a>
          </Dropdown>
        );
      },
    },
  ];
  let NewriskLevelText = [
    {
      key: "",
      label: <Badge color="#000" text="All" />,
    },
    ...riskLevelText,
  ];
  let NewstatusText = [
    {
      key: "",
      label: <Badge color="#425466" text="All" />,
    },
    ...statusText,
  ];
  let NewractionStatusText = [
    {
      key: "",
      label: <Badge color="#606E7B" text="All" />,
    },
    ...actionStatusText,
  ];
  const onFinish = (values: any) => {
    let params = { ...values };
    delete params.date;
    Object.keys(params).map((item: string) => {
      if (item === "date" && params["date"]) {
        params.from =
          new Date(
            dayjs(dayjs(params["date"][0]).valueOf()).format("YYYY-MM-DD")
          ).getTime() / 1000;
        params.to =
          new Date(
            dayjs(dayjs(params["date"][1]).valueOf()).format("YYYY-MM-DD")
          ).getTime() / 1000;
      }
    });
    setSearchForm(params);
    setPagination({
      ...pagination,
      page: 1,
    });
  };
  useEffect(() => {
    getList();
  }, [pagination]);
  const getList = async () => {
    let params = {
      offset:
        pagination.page >= 1 ? (pagination.page - 1) * pagination.pageSize : 0,
      limit: pagination.pageSize,
      ...searchForm,
    };
    let info = await ScenariosAlerts(params);
    setData1(info.items);
    setPaginationData1({
      count: pagination.pageSize,
      limit: pagination.pageSize,
      offset: info._metadata.offset,
      total: info._metadata.total,
    });
  };
  const handleOk = async () => {
    modalForm.validateFields().then(async (values) => {
      await PatchScenariosAlerts(
        modalData?.scenario?.id,
        modalData?.id,
        values
      );
      modalForm.resetFields();
      setIsModalOpen(false);
      getList();
    });
  };

  return (
    <>
      <Header />
      <div className="alert-page-container body-container">
        <Navigation id={t("NotificationsList.title")} />
        <div className="NotificationsListPage-title">
          Transaction Monitoring System
        </div>
        <div className="container-item list-container" style={{ padding: 0 }}>
          <Modal
            title={modalType === 1 ? "Change Status" : "Add Action to Alert"}
            open={isModalOpen}
            onOk={handleOk}
            centered
            onCancel={() => {
              modalForm.resetFields();
              setIsModalOpen(false);
            }}
          >
            <p>
              {modalType === 1
                ? "You can add a justification for the new status. This will be included in the audit trail of the alert."
                : "You can add a justification for the new action. This will be included in the audit trail of the alert."}
            </p>
            <Form
              form={modalForm}
              name="modal_form"
              style={{
                maxWidth: "none",
                padding: 24,
              }}
              layout="vertical"
            >
              <Row gutter={24}>
                {modalType === 1 && (
                  <Col span={24}>
                    <Form.Item
                      name={`status`}
                      label={`New Status:`}
                      rules={[{ required: true }]}
                    >
                      <Select disabled>
                        {statusText.map((item) => {
                          return <Option value={item.key}>{item.label}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                {modalForm.getFieldValue("status") === "RESOLVED" &&
                  modalType === 1 && (
                    <Col span={24}>
                      <div style={{ textAlign: "center" }}>
                        The action status is required when changing an alert
                        status into Resolved
                      </div>
                    </Col>
                  )}
                {(modalForm.getFieldValue("status") === "RESOLVED" ||
                  modalType === 2) && (
                  <Col span={24}>
                    <Form.Item
                      name={`actionStatus`}
                      label={`Action Status:`}
                      rules={[{ required: true }]}
                    >
                      <Select>
                        {actionStatusText.map((item) => {
                          return <Option value={item.key}>{item.label}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                <Col span={24}>
                  <Form.Item
                    name={`comment`}
                    label="Justification:"
                    rules={[{ required: true }]}
                    help="Justification is required"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <div className="NotificationsListPage-search-container">
            <Form
              form={form}
              name="advanced_search"
              style={{
                maxWidth: "none",
                padding: 24,
              }}
              onFinish={onFinish}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name={`name`} label={`Scenario Name`}>
                    <Input placeholder="Search by scenario name" />
                  </Form.Item>
                </Col>
                <Col span={8} offset={8}>
                  <div style={{ textAlign: "right" }}>
                    <Space size="small">
                      <Button type="primary" htmlType="submit">
                        Search
                      </Button>
                      <Button
                        onClick={() => {
                          form.resetFields();
                        }}
                      >
                        Clear
                      </Button>
                    </Space>
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name={`date`} label={`Date Range`}>
                    <RangePicker />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={`riskLevel`} label={`Risk Level`}>
                    <Select defaultValue="">
                      {NewriskLevelText.map((item) => {
                        return <Option value={item.key}>{item.label}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={`status`} label={`Status`}>
                    <Select defaultValue="">
                      {NewstatusText.map((item) => {
                        return <Option value={item.key}>{item.label}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={`actionStatus`} label={`Action Status`}>
                    <Select defaultValue="">
                      {NewractionStatusText.map((item) => {
                        return <Option value={item.key}>{item.label}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <Table
            columns={columns1}
            scroll={{ x: "1000" }}
            dataSource={data1}
            pagination={{
              showSizeChanger: false,
              pageSize: paginationData1.count,
              total: paginationData1.total,
              current: paginationData1.offset / paginationData1.count + 1,
              onChange: (page, pageSize) => {
                setPagination({
                  page,
                  pageSize,
                });
              },
            }}
          />
        </div>
      </div>
    </>
  );
}

export default NotificationsList;
