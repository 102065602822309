import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  search,
  selectLoading,
  selectResult
} from './SearchSlice';
import { CSSTransition } from 'react-transition-group'

import { useTranslation } from 'react-i18next';
import styled from './Search.module.css'
import LoadingIcon from "@/features/common/loading"

import React from 'react';
import { SearchData, SearchType } from './SearchAPI';
import { useNavigate } from 'react-router-dom';
import { selectBlockChain } from '../address/addressSlice';

export function Search() {
  const { t } = useTranslation()

  const loading = useAppSelector(selectLoading);
  const results = useAppSelector(selectResult);
  const blockchains = useAppSelector(selectBlockChain);
  const dispatch = useAppDispatch();
  const [keyword, setKeyword] = useState('');
  const [focus, setFocus] = useState(false)
  
  const nodeRef = React.useRef(null)

  const navigate = useNavigate()

  const clickItem = (data: SearchData) => {
    switch(data.type) {
      case SearchType.Address:
        return navigate(`/address/1/${data.blockchain}/${data.name}`)
      case SearchType.Transaction:
        return navigate(`/trading/${data.blockchain}/${data.name}`)
      case SearchType.Entities:
        return navigate(`/entities/${data.name}/${data.id}`)
    }
  }

  return (
      <div className={styled["search-container"]}>
        <div className={styled.inputwrap}>
          <input
            className={styled.input}
            value={keyword}
            placeholder={t("home.placeholder")}
            onChange={(e) => {
              setKeyword(e.target.value)
              if (e.target.value.length > 0) {
                dispatch(search({keyword: e.target.value}))
                setFocus(e.target.value.length > 0)
              }
            }}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(keyword.length > 0)}
          />
          
          <button
            className={styled.button}
            onClick={() => {
              dispatch(search({keyword}))
              if (keyword.length > 0) {
                setFocus(true)
              }
            }}
          >
            {t("home.button")}
          </button>
        </div>

        <CSSTransition
          in={focus}
          timeout={300}
          classNames="alert"
          nodeRef={nodeRef}
          unmountOnExit>
          <div  ref={nodeRef} className={styled["select-container"]}>

            { loading ? 
              <div className={styled["loading-icon"]}>
                <LoadingIcon size={30} color={"#425466"} />
              </div> : 
              <>
                <p className={styled["result-text"]}>{t("home.resultCountBefore")} <span>{results.length}{t("home.resultCountUnit")}</span> {t("home.resultCountAfter")}</p>
                <div className={styled["select-list"]}>
                  {
                    results.map((i,k) => (
                      <div key={`list-key-${k}`} className={styled["select-item"]}>
                        <div onClick={() => clickItem(i)}>
                          { i.type === SearchType.Entities ? null : <img src={(blockchains && i.blockchain) ? `https://app.scorechain.com/images/cryptoicons/${blockchains[i.blockchain].icon}` : ""} alt="" /> }
                          <p>{i.name}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </>
            }
          </div>
        </CSSTransition>
      </div>
    );
}
