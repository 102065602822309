import { useTranslation } from "react-i18next";
import "./riskAssessment.css";
import securityIcon from "@/assets/security.png";
import warningIcon from "@/assets/warning.png";
import { useAppSelector } from "@/app/hooks";
import { selectEntityInfo } from "../entity/entitySlice";

export default function RiskAssessment() {
  const { t } = useTranslation();

  const entityInfo = useAppSelector(selectEntityInfo);
  if (!entityInfo) return null;

  const riskAssessment = entityInfo.riskAssessment;
  // isVASP:boolean,
  // hasExoticCoins: boolean,
  // hasLegalEntity:boolean,
  return (
    <div className="risk-assessment-container">
      <p className="title">Risk Assessment</p>

      <ul>
        <li>
          <img
            src={riskAssessment.isVASP ? securityIcon : warningIcon}
            alt=""
          />
          <span>
            {riskAssessment.isVASP
              ? "Considered as a VASP"
              : "Not considered as a VASP"}
          </span>
        </li>

        <li>
          <img
            src={riskAssessment.isActive ? securityIcon : warningIcon}
            alt=""
          />
          <span>{riskAssessment.isActive ? "Active" : "Inactive"}</span>
        </li>

        <li>
          <img
            src={riskAssessment.hasExoticCoins ? warningIcon : securityIcon}
            alt=""
          />
          <span>
            {riskAssessment.hasExoticCoins
              ? "Exotic tokens trading are allowed"
              : "Exotic tokens trading are not allowed"}
          </span>
        </li>

        <li>
          <img
            src={riskAssessment.hasLegalEntity ? securityIcon : warningIcon}
            alt=""
          />
          <span>
            {riskAssessment.hasLegalEntity
              ? "Has a legal structure"
              : "Has not any legal structure"}
          </span>
        </li>

        <li>
          <img src={riskAssessment.isP2P ? warningIcon : securityIcon} alt="" />
          <span>
            {riskAssessment.isP2P
              ? "P2P transactions are allowed"
              : "P2P transactions are not allowed"}
          </span>
        </li>

        <li>
          <img
            src={riskAssessment.hasAnonymousCoins ? warningIcon : securityIcon}
            alt=""
          />
          <span>
            {riskAssessment.hasAnonymousCoins
              ? "Anonymous coins transactions allowed"
              : "Anonymous coins transactions are not allowed"}
          </span>
        </li>

        <li>
          <img
            src={riskAssessment.hasBeenHacked ? warningIcon : securityIcon}
            alt=""
          />
          <span>
            {riskAssessment.hasBeenHacked ? "Has been hacked" : "Never hacked"}
          </span>
          {riskAssessment.hasBeenHacked && riskAssessment.noteHacks !== "" ? (
            <p className="detail">{riskAssessment.noteHacks}</p>
          ) : null}
        </li>

        <li>
          <img
            src={riskAssessment.isCashOnly ? warningIcon : securityIcon}
            alt=""
          />
          <span>
            {riskAssessment.isCashOnly
              ? "This VASP offers cash deposits/withdrawals"
              : "This VASP doesn't offer cash deposits/withdrawals"}
          </span>
        </li>
      </ul>
    </div>
  );
}
