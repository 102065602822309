import ForgotPass from "@/views/ForgotPass/ForgotPass";
import Login from "@/views/Login/Index";
import Register from "@/views/Register/Register";

const publicRoutes = [
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/register",
    component: Register,
    exact: true,
  },
  {
    path: "/forgetPass",
    component: ForgotPass,
    exact: true,
  },
];

export default publicRoutes;
