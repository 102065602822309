import { Search } from "@/features/search/Search";
import { useTranslation } from "react-i18next";
import styled from './Index.module.css'
import searchIcons from '@/assets/searchIcons.png'
import Header from "@/features/user/Header";

function Index() {
  document.title = "Falconchain Check Dashboard"
  
  const { t } = useTranslation()
  
  return (
    <>
      <Header />
      <div className={styled.container}>
        <p className={styled.title}>{t("home.title")}</p>
        <Search />
        <img className={styled["search-icons"]} src={searchIcons} alt="" />
      </div>
    </>
  );
}

export default Index;
