import { FormSelect } from "react-bootstrap";
import { AnalysisType } from "./addressAPI";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import {
  blockchainTranslate,
  reverseAnalysisData,
  selectAnalysisError,
  selectAnalysisList,
  selectAssetNav,
  selectLoadScorigingAnalysis,
  showAssets,
} from "./addressSlice";
import LoadingIcon from "@/features/common/loading";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Modal,
  Table,
  Tag,
  Tooltip,
  Badge,
} from "antd";
import dayjs from "dayjs";
import type { ColumnsType, TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function RiskScoreList(props: {
  symbol: string;
  showAssets: boolean;
  onChangeType: Function;
  onChangeSelect?: Function;
}) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [dataType, setDataType] = useState(1);
  const [selectNavIndex, setNavIndex] = useState(0);

  const assetNav = useAppSelector(selectAssetNav);
  const analysisList = useAppSelector(selectAnalysisList);
  const loadAnalysisData = useAppSelector(selectLoadScorigingAnalysis);
  const loadAnalysisError = useAppSelector(selectAnalysisError);

  const changeType = (type: AnalysisType, status: number) => {
    if (loadAnalysisData) return;

    setDataType(status);
    props.onChangeType(type);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      width: 120,
      fixed: true,
      render: (i) => {
        return <>{i}%</>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      ellipsis: true,
      fixed: true,
      render: (i) => {
        return (
          <Tooltip placement="topLeft" title={i}>
            {i}
          </Tooltip>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
    },
    {
      title: "Countervalue(USD)",
      dataIndex: "amountUsd",
      key: "amountUsd",
      width: 200,
    },
    {
      title: `Amount ${props.symbol ? `(${props?.symbol == '-' ? 'N/A' : props?.symbol})` : ""}`,
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (i) => {
        return <>{props?.symbol == '-' ? 'N/A' : i}</>;
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      width: 80,
    },
  ];
  return (
    <>
      <div>
        <div className="title-wrap">
          <p className="title">Risk Assessment</p>
          <div>
            {props.showAssets ? (
              <div className="select-cpt">
                <p>Assets</p>
                <FormSelect
                  defaultValue={0}
                  disabled={loadAnalysisData}
                  onChange={(e) => {
                    if (!props.onChangeSelect || !assetNav) return;
                    const index = e.target.selectedIndex;
                    const chainId =
                      index === 0
                        ? "ALL"
                        : assetNav[index - 1].chainId || "MAIN";
                    props.onChangeSelect(chainId, index);
                    setNavIndex(index);
                  }}
                >
                  <option value={0}>{t("address.totalAssets")}</option>
                  {assetNav &&
                    assetNav.map((i, k) => (
                      <option key={i.chainId} value={k + 1}>
                        {blockchainTranslate(i.name)}
                      </option>
                    ))}
                </FormSelect>
              </div>
            ) : null}
          </div>
        </div>
        {props.showAssets && (
          <div style={{ fontSize: "12px" }}>
            <ExclamationCircleOutlined
              style={{ color: "#f5222d" }}
              rev={undefined}
            />{" "}
            If the address cannot display the global risk assessment chart, it
            is because the address has more than 10,000 transactions with
            multiple assets. Please select one of the assets on the right and
            assess the risk for each asset separately.
          </div>
        )}

        <nav className="nav-list">
          <li
            className={dataType === 1 ? "active" : ""}
            onClick={() => changeType(AnalysisType.Incoming, 1)}
          >
            Incoming Risk Score
          </li>
          <li
            className={dataType === 2 ? "active" : ""}
            onClick={() => changeType(AnalysisType.Outgoing, 2)}
          >
            Outgoing Risk Score
          </li>
        </nav>
        <Table
          scroll={{ x: 800, y: 490 }}
          columns={columns}
          dataSource={analysisList}
          pagination={{
            showSizeChanger: false,
            total: analysisList.length,
            pageSize: 9,
            onChange: (a) => setPage(a - 1),
          }}
        />
      </div>
    </>
  );
}
